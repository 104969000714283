<template>
  <div>
    <!-- pic9F9E52C2D8D7FB25B09845A3AAFC11C2.jpg -->
    <img class="img-large rounded" src="@/assets/news-network/mln-felix.jpg">
    <p>
      If you hear a fun song in LEGO Universe, it could be coming from this fellow! His name is Friendly Felix, and he’s a lyrical bard and poet who’ll soon be rockin’ on My LEGO Network!
    </p>
    <p>
      Long retired from his career as an explorer, Friendly Felix loves telling musical tales of his past adventures. He gets plenty of chances to perform in his current line of work, which is greeting visitors to a huge public garden!
    </p>
    <p>
      A festive feeling fills the air in the gardens where Felix dances and sings his tunes. It’s a place where building, art, and exploration are encouraged, so folks flock there to join in the creative spirit!<br>Friendly Felix fits right in with all the unique characters from every corner of the universe, but he’s not just an offbeat oddball. He’s also an ally of creativity with a knack for figuring out his fellow minifigures’ motivations!
    </p>
    <p>
      Friendly Felix says:
    </p>
    <p>
      “Feeling glum? Bored? Cranky? Kinda’ bummed? Here, take my hanky!<br>Now wipe away that frown and scrub off your smirk! Smile! We can be friends soon on My LEGO Network!”
    </p>
    <p>
      * The LEGO Universe News Network wants to give a special shout out to
      <router-link :to="{ name: 'creation-lab-user', params: {name: 'jumper3485'} }">jumper3485</router-link>! He was the first fan to guess that Friendly Felix is a one man band! How’d you know, jumper?
    </p>
  </div>
</template>
